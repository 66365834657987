<template>
  <UAlert
    v-bind="$attrs"
    :title="title"
    :description="description"
    :color="color"
    :variant="variant"
    :ui="ui"
    @close="handleClose"
  >
    <template v-for="(_, name) in $slots" :key="name" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </UAlert>
</template>

<script setup lang="ts">
interface Props {
  /**
   * The title of the alert
   */
  title?: string
  /**
   * The description text
   */
  description?: string
  /**
   * The color variant to use
   */
  color?: 'gray' | 'red' | 'green' | 'blue'
  /**
   * The style variant to use
   */
  variant?: 'solid' | 'outline' | 'soft' | 'subtle'
}

withDefaults(defineProps<Props>(), {
  title: undefined,
  description: undefined,
  color: undefined,
  variant: undefined
})

const ui = {
  color: {
    gray: 'gray'
  }
}

const emit = defineEmits(['close'])

const handleClose = () => {
  emit('close')
}
</script>
